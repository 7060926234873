<template>
    <b-overlay :show='showLoader'>
        <validation-observer ref="editAuthorValidation">
            <b-form @submit.prevent>
                <b-card>
                    <b-row>
                        <b-col><h3>{{ $t("general.edit_author") }}</h3></b-col>
                        <b-col class="text-right">
                            <b-button variant="secondary" class="ml-2" @click='$router.go(-1)'>{{ $t('general.go_back')}}</b-button>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6'>
                            <validation-provider
                                :name="$t('general.name')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='name'>{{ $t('general.name') }}</label>
                                <b-form-input v-model="author.Firstname" name="name" id="name"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                        <b-col cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.lastname')"
                                #default="{ errors }"
                                rules="required"
                            >
                                <label for='lastname'>{{ $t('general.lastname') }}</label>
                                <b-form-input v-model="author.Lastname" name="lastname" id="lastname"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.birthday')"
                                #default="{ errors }"
                                :rules="{required: true, regex:'^\\d{4}\\-(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$'}"
                            >
                                <label for='date'>{{ $t('general.birthday') }}</label>
                                <b-input-group>
                                    <b-form-input id='date' v-model="author.Birthday" placeholder="LLLL-MM-DD" autocomplete="off"/>
                                    <b-input-group-append>
                                        <b-form-datepicker v-model="author.Birthday" :date-format-options="dateFormat" button-only right aria-controls="date"  start-weekday="1"></b-form-datepicker>
                                    </b-input-group-append>
                                </b-input-group>

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-col>
                        <b-col cols='12' sm='6' class="mt-1 mt-sm-0">
                            <validation-provider
                                :name="$t('general.emailAddress')"
                                #default="{ errors }"
                                rules="email"
                            >
                                <label for='emailAddress'>{{ $t('general.emailAddress') }}</label>
                                <b-form-input v-model="author.Email" name="emailAddress" id="emailAddress"></b-form-input>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col class="text-right">
                            <b-button @click="validationForm" variant="primary" class="mt-2">{{ $t('general.save') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-form>
        </validation-observer>
    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput, BFormDatepicker, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import { required, email } from '@validations'

    export default {
        components: {BCol, BRow, BOverlay, BCard, BForm, BButton, BFormInput, BFormDatepicker, BInputGroup, BInputGroupAppend, ValidationProvider, ValidationObserver},
        data() {
            return {
                id: 0,
                showLoader: false,
                dateFormat: { 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' },
                author: {
                    Firstname: '',
                    Lastname: '',
                    Birthday: '',
                    Email: ''
                },
                date: '',
                required,
                email
            }
        },
        methods: {
            validationForm() {
    
                this.$refs.editAuthorValidation.validate().then((response) => {
                    if (response) {
                        this.onEditAuthor()
                    }
                })
            },
            loadAuthor() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get(`/v1/admin/avtor/${this.id}`)
                    .then(response => {
                        thisIns.author = response.data
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                    }).finally(() => {
                        thisIns.showLoader = false
                    })
            },
            onEditAuthor() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    Firstname: thisIns.author.Firstname.charAt(0).toUpperCase() + thisIns.author.Firstname.slice(1).toLowerCase(),
                    Lastname: thisIns.author.Lastname.charAt(0).toUpperCase() + thisIns.author.Lastname.slice(1).toLowerCase(),
                    Birthday: thisIns.author.Birthday,
                    Email: thisIns.author.Email
                }
        
                thisIns.$http.patch(`/v1/admin/avtor/${this.id}`, payload)
                    .then(() => {
                        thisIns.$printSuccess(`${thisIns.$t('general.author_successfully_edited')}`)

                        thisIns.showLoader = false

                        setTimeout(() => {
                            thisIns.$router.push({name: 'admin-authors'})
                        }, 500)
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.edit_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            }
        },
        created()  {
            this.id = this.$route.params.author_id
        },
        mounted() {
            this.loadAuthor()
        }
    }
</script>